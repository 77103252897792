import "./BubbleEffect.css";

const BubbleEffect = () => {
  return (
    <section className="w-full h-screen overflow-hidden z-[1] absolute pointer-events-none">
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
      <div class="snow-1"></div>
    </section>
  );
};

export default BubbleEffect;
